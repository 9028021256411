function stdRowTimeline(containerId) {
	var tlm = new TimelineMax();

  tlm.staggerTo(containerId + " .home-claim h2 .first", 0.45, {
      opacity: 1,
      ease: Power1.easeInOut
  }, 0, 0)
	.staggerTo(containerId + " .home-claim h2 .first", 0.25, {
      webkitFilter: "blur(0px)"
  }, 0, 0.1)
	.staggerTo(containerId + " .home-claim img", 0.35, {
      opacity: 1,
			x: 0,
      ease: Power1.easeIn
  }, 0, 0.4)
	.staggerTo(containerId + " .home-claim h2 .second", 0.25, {
      opacity: 1,
      ease: Power1.easeInOut
  }, 0, 0.9)
	.staggerTo(containerId + " .home-claim h2 .second", 0.3, {
      webkitFilter: "blur(0px)"
  }, 0, 0.10);

	return tlm;
}

function stdRowFromBottom(containerId) {
	var tlm = new TimelineMax();

  tlm.staggerTo(containerId + " .home-claim h2 .first", 0.45, {
      opacity: 1,
      ease: Power1.easeInOut
  }, 0, 0)
	.staggerTo(containerId + " .home-claim h2 .first", 0.25, {
      webkitFilter: "blur(0px)"
  }, 0, 0.1)
	.staggerTo(containerId + " .home-claim img", 0.35, {
      opacity: 1,
			x: 0,
			y: 0,
      ease: Power1.easeIn
  }, 0, 0.4)
	.staggerTo(containerId + " .home-claim h2 .second", 0.25, {
      opacity: 1,
      ease: Power1.easeInOut
  }, 0, 0.9)
	.staggerTo(containerId + " .home-claim h2 .second", 0.3, {
      webkitFilter: "blur(0px)"
  }, 0, 0.10);

	return tlm;
}

function homeFirstAnimation() {
	var tlm = new TimelineMax();

	tlm.staggerTo(".fc-black", 0.30, {
		top: "100%",
		ease: Power1.easeInOut
  }, "+=1")
	.staggerTo("#fc-first svg", 0.50, {
		scale: 50,
		opacity: 0,
		ease: Power1.easeInOut
  }, "+=1")
	.staggerTo(".almaware-fixed-controls", 0.20, {
		opacity: 1,
		ease: Power1.easeInOut
  }, "+=1")
	.staggerTo(".fixed-scroll", 0.20, {
		opacity: 0,
		ease: Power1.easeInOut
  }, "+=1")
	.staggerTo(".fc-claim", 0.20, {
		opacity: 1,
		ease: Power1.easeInOut
  }, "+=1");


	return tlm;
}

function stdIconTimeline(containerId) {
	var tlm = new TimelineMax();

  tlm.staggerFrom(containerId, 0.45, {
      xPercent: -18,
      opacity: 1,
      ease: Power1.easeInOut
  }, 0, 0);

	return tlm;
}

jQuery(function(){
	// init controller
	var controller = new ScrollMagic.Controller();

	// almaware Experience Scene
	if(jQuery('#almaware-experience').length) {

		// First section, with img as BG
	  var topClaim =
      new ScrollMagic.Scene({
          triggerElement: "#almaware-experience",
          reverse: !0,
          offset: 300,
          duration: 300,
          triggerHook: 0
      }).setTween(TweenMax.to(".almaware-exp-main-bg", 1, {
          opacity: 0,
          ease: Power1.easeInOut
      }))
      //.addIndicators({name: "Top claim"})
      .addTo(controller);

		// Second section, 1979
	  new ScrollMagic.Scene({
	      triggerElement: "#timeline79",
	      offset: 0,
	      duration: 400,
	      triggerHook: 0
	  }).setPin("#timeline79 .home-claim").addTo(controller);

	  new ScrollMagic.Scene({
	      triggerElement: "#timeline79",
	      offset: -300,
	      duration: 550,
	      triggerHook: 0
	  }).setTween(stdRowTimeline("#timeline79"))
		//.addIndicators({name: "Timeline79"})
		.addTo(controller);

		// Third section, the one that tell about the knowhow
	  new ScrollMagic.Scene({
	      triggerElement: "#applications",
	      offset: 0,
	      duration: 650,
	      triggerHook: 0
	  }).setPin("#applications .home-claim").addTo(controller);

	  new ScrollMagic.Scene({
	      triggerElement: "#applications",
	      offset: -100,
	      duration: 550,
	      triggerHook: 0
	  }).setTween(stdRowFromBottom("#applications"))
		//.addIndicators({name: "Know How"})
		.addTo(controller);

		// Fourth section, the one that tell about the focus
	  new ScrollMagic.Scene({
	      triggerElement: "#agencies",
	      offset: 0,
	      duration: 650,
	      triggerHook: 0
	  }).setPin("#agencies .home-claim").addTo(controller);

	  new ScrollMagic.Scene({
	      triggerElement: "#agencies",
	      offset: -100,
	      duration: 550,
	      triggerHook: 0
	  }).setTween(stdRowTimeline("#agencies"))
		//.addIndicators({name: "Focus"})
		.addTo(controller);

		// Icons
		new ScrollMagic.Scene({triggerElement: "#icon-mobile", offset: -50 })
				.setClassToggle("#icon-mobile", "active") // add class toggle
				//.addIndicators() // add indicators (requires plugin)
				.addTo(controller);

		new ScrollMagic.Scene({triggerElement: "#icon-wordpress", offset: -50 })
				.setClassToggle("#icon-wordpress", "active") // add class toggle
				//.addIndicators() // add indicators (requires plugin)
				.addTo(controller);

		new ScrollMagic.Scene({triggerElement: "#icon-alexa", offset: -50 })
				.setClassToggle("#icon-alexa", "active") // add class toggle
				//.addIndicators() // add indicators (requires plugin)
				.addTo(controller);

		new ScrollMagic.Scene({triggerElement: "#icon-aws", offset: -50 })
				.setClassToggle("#icon-aws", "active") // add class toggle
				//.addIndicators() // add indicators (requires plugin)
				.addTo(controller);
        
    new ScrollMagic.Scene({triggerElement: "#icon-consulenza", offset: -50 })
				.setClassToggle("#icon-consulenza", "active") // add class toggle
				//.addIndicators() // add indicators (requires plugin)
				.addTo(controller);
	}

	// Homepage Scene
	if(jQuery('#fancyhome-container').length) {

		new ScrollMagic.Scene({
				triggerElement: "#fc-first",
				offset: 0,
				duration: 2000,
				triggerHook: 0
		}).setPin("#fc-first").addTo(controller);

		new ScrollMagic.Scene({
				triggerElement: "#fc-first",
				offset: 0,
				duration: 2000,
				triggerHook: 0
		}).setTween(homeFirstAnimation())
		//.addIndicators({name: "Timeline79"})
		.addTo(controller);

		new ScrollMagic.Scene({triggerElement: ".actual-footer", offset: -50 })
				.setClassToggle(".almaware-fixed-logo", "hidden-logo") // add class toggle
				//.addIndicators() // add indicators (requires plugin)
				.addTo(controller);

	}

	if(jQuery('.top-navigation').length && jQuery('.main-container').length) {
		new ScrollMagic.Scene({triggerElement: ".main-container", offset: -150 })
				.setClassToggle("header.sticky-navigation", "reduced") // add class toggle
				//.addIndicators() // add indicators (requires plugin)
				.addTo(controller);
	}

	if(jQuery('.top-navigation').length && jQuery('.top-claim').length) {
		new ScrollMagic.Scene({triggerElement: "main", offset: jQuery(window).height() / 2 + 150})
				.setClassToggle("header.sticky-navigation", "reduced") // add class toggle
				//.addIndicators() // add indicators (requires plugin)
				.addTo(controller);
	}

	/*if(jQuery('.vision-mission-container').length) {
		jQuery('.vision-mission-container').each(function (i, element) {
			console.log(jQuery(this).outerHeight());

	    new ScrollMagic.Scene({
	      duration: jQuery(this).outerHeight() * 0.75,
				offset: jQuery(this).outerHeight() * 0.25,
	      triggerElement: element
	    })
	    .setClassToggle(element, "apply-offset") // add class toggle
	    .addTo(controller);
	    //.addIndicators(controller);
	  });
	}*/
});
