jQuery(function(){
  jQuery(".social-icon").width(jQuery(".social-icon").height());
  jQuery(".menu-social a").width(jQuery(".menu-social a").height());
  jQuery(".term-icon").height(jQuery(".term-icon").width());
  jQuery(".ab-icon a").height(jQuery(".ab-icon a").width());
  jQuery(".rhi-number span").width(jQuery(".rhi-number span").height());
  jQuery(".fancy-hover-badge").height(jQuery('.fancy-hover-badge').width() * 1.7);

  jQuery(document).ready(function(){
    jQuery("#product_tab a").click(function(e){
      setTimeout(function(){
        jQuery(".ab-icon a").height(jQuery(".ab-icon a").width());
      }, 200);
    });
  });

  setTimeout(function(){
    var h = 0;

    jQuery('.care-row').each(function(){
      if(jQuery(this).height() > h) {
        h = jQuery(this).height();
      }
    });

    jQuery('.care-row').height(h);
  }, 200);
});
