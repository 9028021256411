jQuery(document).ready(function () {
  // Click on hamburger
  jQuery('#sidebarCollapse').on('click', function () {
    // Sidebar jumps in and out
    jQuery(".sidebar-content li").removeClass('visible');

    jQuery('#sidebar').toggleClass('active').promise().done(
      function() {
        // The timeout is to start the animation only after the sidebar is completely out
        setTimeout(function() {
          jQuery(".sidebar-content li").each(function(index) {
            if(!jQuery('#sidebar').hasClass('active')) {
              var li = jQuery(this);

              setTimeout(function() {
                li.stop().addClass('visible');
              }, 300 * index);
            }
          });
        }, 400);
      });
    jQuery(this).toggleClass('is-active');
  });

  jQuery('.special-btn-company').click(function(e){
    e.preventDefault();
    jQuery('.fancy-submenu').not('#company').removeClass('opensub');
    jQuery('#company').toggleClass('opensub');
  });

  jQuery('.special-btn-systems').click(function(e){
    e.preventDefault();
    jQuery('.fancy-submenu').not('#systems').removeClass('opensub');
    jQuery('#systems').toggleClass('opensub');
  });
});
